import Project from '../Project/Project'
import './Projects.css'
import MausamImg from './assets/mausam-cloud.png'
import PatientImg from './assets/patient-restful.png'
import NewsImg from './assets/news-term.png'

export default function Projects() {
    return(
        <section>
            <div className='page-content'>
                <div className='project-list'>
                    <Project src={MausamImg} heading={"Mausam App"} description={"A Weather App built using ReactJS. Powered by Open Weather API"} github={"https://github.com/anmolsekhon590/mausam/"} />
                    <Project src={PatientImg} heading={"Patient RESTful API"} description={"An API built using Spring Boot Framework"} github={"https://github.com/anmolsekhon590/patientRESTful"} />
                    <Project src={NewsImg} heading={"news-term"} description={"Read news from your terminal"} github={"https://github.com/anmolsekhon590/news-term"} />
                
                </div>
            </div>
        </section>
    )
}