import avatar from '../About/assets/bmo.png'
import './About.css'
import GithubImg from './assets/github.svg';
import LinkedinImg from './assets/linkedin.svg';
import SuitcaseImg from './assets/suitcase.svg';
import BoxImg from './assets/box.svg';
import { Link } from "react-router-dom";

export default function About() {
    return (
            <section>
                <div className='page-content'>

                    <div className='intro'>
                        <img alt="avatar" className='avatar' src={avatar} />
                        <h2>I'm Anmol</h2>

                        <p><img alt="suitcase logo" className='ref-icon' src={SuitcaseImg} /><span>Software Developer at <b><a href="https://www.payfirma.com/">Payfirma</a></b></span></p>
                        <p><img alt="open box logo" className='ref-icon' src={BoxImg} /><span>Checkout my open source projects <Link to="/projects" >here</Link></span></p>
                    </div>

                    <hr className='divider'></hr>

                    <h3>Connect with me:</h3>
                    <div className='social'>
                        <a href='https://github.com/anmolsekhon590'><img alt="GitHub icon" className='ref-icon' src={GithubImg} />GitHub</a>
                        <a href='https://www.linkedin.com/in/anmol-sekhon/'><img alt="Linkedin icon" className='ref-icon' src={LinkedinImg} />LinkedIn</a>
                    </div>
                </div>
            </section>
    )
}