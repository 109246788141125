import React from "react";
import BmoBroken from "./assets/bmo-broken.jfif";
import { Link } from "react-router-dom";

export default function Notfound() {
    return (

        <section>
            <div className="page-content">
                <img className="bmo-broken" src={BmoBroken} />
                <p>Error 404: Not Found</p>
                <Link to="/">Go To Home</Link>
            </div>
        </section>

    );
}