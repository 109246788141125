import './Project.css'

export default function Project(props) {
    return (
        <div className='project-container'>
            <div className='icon-container'>
                <img className="project-icon" src={props.src} />
            </div>

            <div className='description-container'>
                <h3>{props.heading}</h3>
                <p>{props.description}</p>
                <div className='project-link'>
                    <a href={props.github}>Github</a>
                </div>
            </div>

        </div>
    )
}