import './Contact.css'

export default function Contact() {
    return (
        <div className='page-content'>
            <div>
                <form action="https://formspree.io/f/moqyplwe" method='POST'>

                    <div className='input-group'>
                        <label htmlFor="email">Email:</label>
                        <input className='email-field' type="email" name='email' required />
                    </div>

                    <div className='input-group'>
                        <label htmlFor="message">Message:</label>
                        <input className="message-field" type="text" name="message" required ></input>
                    </div>

                    <input className='submit-btn' type="submit" value="Send" />
                </form>
            </div>
        </div>
    )
}