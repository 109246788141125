import './Navbar.css';
import React from "react";
import {
    HashRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import About from '../About/About';
import Projects from '../Projects/Projects';
import Contact from '../Contact/Contact';
import AboutImg from '../Navbar/assets/user.svg';
import ProjectsImg from '../Navbar/assets/folder.svg';
import ContactImg from '../Navbar/assets/email.svg';
import NotFound from '../NotFound/NotFound';

export default function Navbar() {
    return (
        <Router basename="/">

            <div className="navbar">

                <Link className='navitem' to="/">
                    <img alt="nav icon" className='ref-icon' src={AboutImg} />
                    <span>About</span>
                </Link>


                <Link className='navitem' to="/projects">
                    <img alt="nav icon" className='ref-icon' src={ProjectsImg} />
                    <span>Projects</span>
                </Link>

                <Link className='navitem' to="/contact">
                    <img alt="nav icon" className='ref-icon' src={ContactImg} />
                    <span>Contact</span>
                </Link>
            </div>

            <Routes>
                <Route exact path="/" element={<About />} />
                <Route exact path="/projects" element={<Projects />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route path="*" exact element={<NotFound />} />
            </Routes>
            
        </Router>
    )
}